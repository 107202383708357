// ============================================================
// ATTENTION & COMMON RULE!!
// 関数を実装のみ（処理の実行は下部で実行する）
// 関数名には振る舞いを表す英語プラスFuncを付ける
// ============================================================

// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
// -------------------------------------------------------------

function mq(device) {
  if (window.matchMedia('(max-width:768px)').matches && device === 'sp') {
    return true;
  } else if (
    window.matchMedia('(min-width:769px)').matches &&
    device === 'pc'
  ) {
    return true;
  }
  return false;
}
// FUNCTIONS

function lazyLoadVideo() {
  var videoPc = document.getElementById("videoPc");

  var xhr = new XMLHttpRequest();
  xhr.open("GET", "/img/pc.mp4", true);
  xhr.responseType = "blob";
  console.log(xhr);
  xhr.onload = function(e) {
    if (this.status === 200) {
      console.log("videoPc ロード完了");
      // ロード完了
      var blob = this.response;
      var src = (window.webkitURL || window.URL).createObjectURL(blob);
    }
    console.log("videoPc loaded!!!");
    videoPc.src = "/img/pc.mp4";
    videoPc.play();
    document.getElementById("headerWrap").classList.add('show');
  };
  xhr.send();

}

function topPageFunc() {
  if (mq("sp")) {
    var topSwiper = new Swiper("#sliderWrapFirst", {
      effect: "fade",
      speed: 3000,
      pagination: {
        el: ".swiper-pagination"
      },
      autoplay: {
        delay: 4000
      },
      loop: true
    });
    document.getElementById("headerWrap").classList.add('show');

  } else {
    lazyLoadVideo();
  }
}



function subPageFunc() {
  console.log('SUB PAGE');
  // $('body').css('overflow', 'auto');
  // $('body').css('height', 'auto');
  $('.header').addClass('show');
  $('.menu-trigger').addClass('show');
  $('.main-image').addClass('show');
  $('#firstImageWrap').addClass('show');
  $('#firstText01').addClass('show');
  if($('#koujinagareWrap').length){
    $('.nagare-photo-list a').magnificPopup({
      type: 'image',
      gallery: {  //ギャラリーオプション
        enabled:true
      }
    });
    $('#nagareTitle').click(function(){
      $('.nagare-list-wrap').toggleClass('show');
    });
    var _listTargets = document.querySelectorAll('.nagare-list a');
    for(var i = 0; i < _listTargets.length; i+= 1){
      _listTargets[i].addEventListener('click', function(e){
        console.log(e.currentTarget);
        for(var _i = 0; _i < _listTargets.length; _i+= 1){
          _listTargets[_i].classList.remove('active');
        }
        e.currentTarget.classList.add('active');
        $('.nagare-list-wrap').removeClass('show');

      }, false);
    }
    
  }

  if ($('#sekouGallery').length) {
    $('#sekouGallery').magnificPopup({
      delegate: 'a', // child items selector, by clicking on it popup will open
      type: 'image',
      titleSrc: 'title',
      gallery: { enabled: true }
      // other options
    });
  }

  if ($("#gojyoGallery1").length) {

    var swiper06Main = new Swiper ('#gojyoGallery1 .main', {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      spaceBetween: 10,
    });
  
    var swiper06Thumb = new Swiper('#gojyoGallery1 .thumbnail', {
      controller: {
        control: swiper06Main,
        inverse: false,
        by: 'slide'
      },
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: 5,
      slideToClickedSlide: true
    });
    swiper06Main.controller.control = swiper06Thumb;
    // swiper06Thumb.controller.control = swiper06Main;

      var swiper02Main = new Swiper ('#gojyoGallery2 .main', {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      spaceBetween: 10,
    });
  
    var swiper02Thumb = new Swiper('#gojyoGallery2 .thumbnail', {
      controller: {
        control: swiper02Main,
        inverse: false,
        by: 'slide'
      },
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: 5,
      slideToClickedSlide: true
    });
    swiper02Main.controller.control = swiper02Thumb;

  }
  
}

function headerChangeBg() {
  var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  if (scrollTop < 1) {
    document.getElementById('headerWrap').classList.remove('bg');
  } else {
    document.getElementById('headerWrap').classList.add('bg');
  }
}

// eslint-disable-next-line no-unused-vars
function GetQueryString() {
  if (document.location.search.length > 1) {
    // 最初の1文字 (?記号) を除いた文字列を取得する
    var query = document.location.search.substring(1);

    // クエリの区切り記号 (&) で文字列を配列に分割する
    var parameters = query.split('&');

    // eslint-disable-next-line no-new-object
    var result = new Object();
    for (var i = 0; i < parameters.length; i++) {
      // パラメータ名とパラメータ値に分割する
      var element = parameters[i].split('=');

      var paramName = decodeURIComponent(element[0]);
      var paramValue = decodeURIComponent(element[1]);

      // パラメータ名をキーとして連想配列に追加する
      result[paramName] = decodeURIComponent(paramValue);
    }
    return result;
  }
  return null;
}



function formCheck(){
  //エラーを表示する関数（error クラスの p 要素を追加して表示）
  function show_error(message, this$) {
    console.log(this$[0].getAttribute('id'));
    var error_text = $('#'+this$[0].id+'_title').val();
    this$.parent().append("<p class='error'>"+error_text+"は必須項目です</p>")
  }
  
  //フォームが送信される際のイベントハンドラの設定
  $("#formPage1").submit(function(){
    //エラー表示の初期化
    $("p.error").remove();
    $("div").removeClass("error");
    var text = "";
    $("#errorDispaly").remove();
    
    // return false;

    //メールアドレスの検証
    var email =  $.trim($("#email").val());
    if(email && !(/^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/gi).test(email)){
      $("#email").after("<p class='error'>メールアドレスの形式が異なります</p>")
    }
    //確認用メールアドレスの検証
    // var email_check =  $.trim($("#email_check").val());
    // if(email_check && email_check != $.trim($("input[name="+$("#email_check").attr("name").replace(/^(.+)_check$/, "$1")+"]").val())){
    //   show_error("が異なります", $("#email_check"));
    // }
    //電話番号の検証
    var tel = $.trim($("#tel").val());
    if(tel && !(/^\(?\d{2,5}\)?[-(\.\s]{0,2}\d{1,4}[-)\.\s]{0,2}\d{3,4}$/gi).test(tel)){
      $("#tel").after("<p class='error'>電話番号の形式が異なります（半角英数字でご入力ください）</p>")
    }

     //カナの検証
     var kana = $.trim($("#kana").val());
     if(kana && !kana.match(/^[ァ-ヶー　]+$/)){
       $("#kana").after("<p class='error'>全角カタカナではない文字が含まれています</p>")
     }
    
    //1行テキスト入力フォームとテキストエリアの検証
    $(".validate").each(function(){
      //必須項目の検証
      $(this).filter(".required").each(function(){
        // console.log($(this));
        if($(this).val()==""){
          show_error("必須項目です", $(this));
        }
      })  
      //文字数の検証
      $(this).filter(".max30").each(function(){
        if($(this).val().length > 30){
          show_error(" は30文字以内です", $(this));
        }
      })
      $(this).filter(".max50").each(function(){
        if($(this).val().length > 50){
          show_error(" は50文字以内です", $(this));
        }
      })
      $(this).filter(".max100").each(function(){
        if($(this).val().length > 100){
          show_error(" は100文字以内です", $(this));
        }
      })
      $(this).filter(".max1000").each(function(){
        if($(this).val().length > 1000){
          show_error(" は1000文字以内でお願いします", $(this));
        }
      }) 
    })

    //error クラスの追加の処理
    if($("p.error").length > 0){
      // $("p.error").parent().addClass("error");
      $('html,body').animate({ scrollTop: $("p.error:first").offset().top-180 }, 'slow');
      return false;
    }
  })



}



// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on('load', function() {
  $('.sub-page .head-line').addClass('show');
});

// --- リサイズが走った場合 ----------------------------------------
$(window).on('resize', function() {});

// --- DOM生成後 -----------------------------------------------
$(function() {
  console.log('script.js');

  var observer = lozad('.lozad', {
    loaded: function(el) {
      // Custom implementation on a loaded element
      el.classList.add('loaded');

    }
  });
  // lazy loads elements with default selector as '.lozad'
  observer.observe({});
  
  if ($("#topPage").length) {
    topPageFunc();
  } else {
    subPageFunc();
    if($("#formPage1").length){
      formCheck();
    }
  }

  
 
  // PC or SPサンプル
  if (mq('sp')) {
    // MENU
    $('#menu-trigger').click(function() {
      if ($('body').hasClass('menu-show')) {
        $('body').removeClass('menu-show');
      } else {
        $('body').addClass('menu-show');
      }
    });
    // if($('#nagareTitle').length){
    //   // SMOOTH SCROOL FOR CLASS NAME
    //   $('a[href^="#"]').click(function() {
    //     var speed = 500;
    //     var href = $(this).attr("href");
    //     var headerHeight = 230;
    //     var target = $(href == "#" || href == "" ? "html" : href);
    //     var position = target.offset().top - headerHeight;
    //     $("html, body").animate({ scrollTop: position }, speed, "swing");
    //     return false;
    //   });
  
    // }else{
      // SMOOTH SCROOL FOR CLASS NAME
      $('a[href^="#"]').click(function() {
        var speed = 500;
        var href = $(this).attr("href");
        var headerHeight = 100;
        var target = $(href == "#" || href == "" ? "html" : href);
        var position = target.offset().top - headerHeight;
        $("html, body").animate({ scrollTop: position }, speed, "swing");
        return false;
      });
  
    // }
  } else {

    // SMOOTH SCROOL FOR CLASS NAME
    $('a[href^="#"]').click(function() {
      var speed = 500;
      var href = $(this).attr("href");
      var headerHeight = 100;
      var target = $(href == "#" || href == "" ? "html" : href);
      var position = target.offset().top - headerHeight;
      $("html, body").animate({ scrollTop: position }, speed, "swing");
      return false;
    });
  
  }
});

window.addEventListener('scroll', _.throttle(headerChangeBg, 500));